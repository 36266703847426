
let remap = (value, low1, high1, low2, high2) => {
    return low2 + (high2 - low2) * (value - low1) / (high1 - low1)
}

import Easing from 'easing-functions'

class Player {
	constructor( audio, loader ) {
		this.loader = loader || console.warn( 'Player requires loader' )
		this.audio = audio || console.warn( 'Player requires audio' )
		this.currentTime = 0
		this.startTime = 0
		this.progress = () => { 
			this.currentTime = Date.now() * .001 - this.startTime
			let thisPosition = this.currentTime / this.duration
			return thisPosition >= 1 ? 0 : thisPosition
		}
		this.paused = true
	}
	
	
	
	play = ()  => {
		console.log( this.currentTime, this.audio )
		if( !this.startTime ) this.startTime = Date.now() * .001
		this.audio.start( this.currentTime )
		this.playPause.classList.remove( 'play' )
		this.playPause.classList.add( 'pause' )
		this.justPlayed = true
		this.paused = false
	}

	pause = ()  => {
		this.audio.stop()
		this.playPause.classList.remove( 'pause' )
		this.playPause.classList.add( 'play' )
		this.paused = true
	}

	newTrack = ( thisAudio ) => {
		console.log( 'new track with audio:', thisAudio )
		this.pause()
		this.currentTime = 0
		this.startTime = 0
		this.setCurrentAudio( thisAudio )
		this.seek()
		this.trackId.classList.remove( 'hide' )
		setTimeout( () => { this.trackId.classList.add( 'hide' ) }, 5000 ) 
	}

	togglePausePlay = () => {
		this.trackId.classList.remove( 'hide' )
		setTimeout( () => { this.trackId.classList.add( 'hide' ) }, 5000 ) 
		this.paused ? this.play() : this.pause()
	}

	attachElements = ( ppb, previous, next, trackId )  => {
		this.trackId = trackId
		this.playPause = ppb
		this.nextButton = next
		this.nextButton.onclick = this.loader.next
		this.previousButton = previous
		this.previousButton.onclick = this.loader.previous
	}

	seek = () => {
		this.audio.seek( this.currentTime )
	}

	updateNode = ( node, x, y ) => {

		let ye = Easing.Sinusoidal.InOut( y )
		let xe = Easing.Sinusoidal.InOut( x )
		let scalar = 24

		let fade1 = ( 1 - xe ) * ( 1 - ye )
		let fade2 = ( xe ) * ( 1 - ye )
		let fade3 = ( 1 - xe ) * ( ye )
		let fade4 = ( xe ) * ( ye )

		this.audio[ 'audio1' ].volume.value = Math.min( -scalar * .6 + fade1 * scalar, 0 )
		this.audio[ 'audio2' ].volume.value = Math.min( -scalar * .6 + fade2 * scalar, 0 )
		this.audio[ 'audio3' ].volume.value = Math.min( -scalar * .6 + fade3 * scalar, 0 )
		this.audio[ 'audio4' ].volume.value = Math.min( -scalar * .6 + fade4 * scalar, 0 )

	
	}

	setCurrentAudio = ( audio ) => {
		if( this.audio ) this.audio.stop()
		this.audio = audio
		this.duration = audio.audio1.buffer.duration
	}
}

export default Player