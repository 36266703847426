// import config from '../../public/assets/config/config.json'

import url from 'url'

class Loader {
	constructor ( config ) {
	
		this.url = url.parse( window.location.href )

		if( this.url.hash ) {
			this.trackNumber = parseFloat( this.url.hash.slice( 1 ) );
		} else {
			this.trackNumber = 1
			window.location.hash = 1
		}

		for( var i = 0; i < config.tracks.length; i++ ) {
			config.tracks[ i ].trackNumber = i + 1
		}

		this.config = config

		this.title = config.title
		this.artist = config.artist
		this.year = config.year
		this.about = config.about
		this.trackList = config.tracks
		this.track = config.tracks[ parseFloat( this.trackNumber ) - 1 ]
        this.colors = this.track.colors

    }

    next = () => {

    	console.log( 'next' )
    	let next = this.trackNumber + 1
    	if( next > this.trackList.length ) next = 1
    	this.trackNumber = next
    	this.activateTrack()
    	// this.player.audio = this.tracks[ trackNumber ].audio
    	// window.location.href = this.url.protocol + '//' + this.url.host + '/#' + next
    	// document.location.reload()


    }

    previous = () => {

    	console.log( 'prev' )
    	let prev = this.trackNumber - 1
    	if( prev < 1 ) prev = this.trackList.length
    	this.trackNumber = prev
    	this.activateTrack()
    	// this.player.audio = this.tracks[ trackNumber ].audio
    	// window.location.href = this.url.protocol + '//' + this.url.host + '/#' + prev
    	// document.location.reload()
    }

    activateTrack = () => {
    	console.log( 'activating ', this.trackNumber )
    	this.track = this.tracks[ this.trackNumber ]
    	window.location.hash = this.trackNumber
    	this.player.trackId.innerText = this.config.tracks[ this.trackNumber - 1 ].title
    	this.player.newTrack( this.track.audio )
    	this.xyPad.newTrack( this.config.tracks[ this.trackNumber - 1 ].colors )
    	if( this.track.loaded ) {
    		this.player.play()
    		this.loading.style.display = "none"
    	} else {
    		this.notLoaded = true
    		this.loading.style.display = "flex"
    	}
    }

    onLoad = ( thisNumber ) => {

    	if( this.notLoaded && thisNumber === this.trackNumber ) {
    		this.player.play()
    		this.loading.style.display = "none"
    		this.notLoaded = false
    	}

    }
}

export default Loader