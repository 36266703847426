import Simplex from 'open-simplex-noise'
import lerp from 'lerp'
import convert from 'color-convert'

let remap = (value, low1, high1, low2, high2) => {
    return low2 + (high2 - low2) * (value - low1) / (high1 - low1)
}

class XYPad {
  constructor ( player, el, images, colors ) {
    this.player = player
  	this.element = el
    this.node1 = document.getElementById( 'node1' )
    this.node1.x = 0
    this.node1.y = 0
    this.lfo1 = 0

    this.pane1 = document.getElementById( 'pane1' )
    this.pane2 = document.getElementById( 'pane2' )

    // HSL ORIGINAL
    this.colors = colors

    this.simplex = new Simplex(Date.now())

    window.addEventListener( 'resize', this.resize.bind( this ), false )

  }

  reset = () => {

    let newX, newY
    newX = Math.random() * .25 + .25
    newY = Math.random() * .25 + .25
    this[ 'node1' ].last = [ newX, newY ]
    this.updateNode( 'node1', newX, newY, false )


  }

  updateNode = ( node, x, y, isHuman ) => {
    
    // console.log( 'updateNode', node, x, y, isHuman ? true : false )

    if( isHuman ) {
      this.lastUpdated = this.time
      this[ node ].last = [ x, y ]
    }  else {
      this[ node ].style.transform = "translate3d( " + ( x * window.innerWidth - window.innerWidth * .5 * .5 ) + "px, " + ( y * window.innerHeight - window.innerHeight * .5 * .5 ) + "px, " + "0px )"
    }

  
    if( x > .75 ) x = .75
    if( x < .25 ) x = .25
    x = remap( x, .25, .75, 0, 1 )
    if( y > .75 ) y = .75
    if( y < .25 ) y = .25
    y = remap( y, .25, .75, 0, 1 )
    

    this[ node ].x = x
    this[ node ].y = y



    // console.log( 'this node x,y :', x, y )

    this.player.updateNode( node, x, y )

    let p1Color = []
    let p2Color = []

    p1Color[ 0 ] = ( this.colors.tl[ 0 ][ 0 ] * ( ( 1 - x ) * ( 1 - y ) ) ) +
                   ( this.colors.tr[ 0 ][ 0 ] * ( ( x ) * ( 1 - y ) ) ) +
                   ( this.colors.bl[ 0 ][ 0 ] * ( ( 1 - x ) * ( y ) ) ) +
                   ( this.colors.br[ 0 ][ 0 ] * ( ( x ) * ( y ) ) )

    p1Color[ 1 ] = ( this.colors.tl[ 0 ][ 1 ] * ( ( 1 - x ) * ( 1 - y ) ) ) +
                   ( this.colors.tr[ 0 ][ 1 ] * ( ( x ) * ( 1 - y ) ) ) +
                   ( this.colors.bl[ 0 ][ 1 ] * ( ( 1 - x ) * ( y ) ) ) +
                   ( this.colors.br[ 0 ][ 1 ] * ( ( x ) * ( y ) ) )

    p1Color[ 2 ] = ( this.colors.tl[ 0 ][ 2 ] * ( ( 1 - x ) * ( 1 - y ) ) ) +
                   ( this.colors.tr[ 0 ][ 2 ] * ( ( x ) * ( 1 - y ) ) ) +
                   ( this.colors.bl[ 0 ][ 2 ] * ( ( 1 - x ) * ( y ) ) ) +
                   ( this.colors.br[ 0 ][ 2 ] * ( ( x ) * ( y ) ) )

    p2Color[ 0 ] = ( this.colors.tl[ 1 ][ 0 ] * ( ( 1 - x ) * ( 1 - y )) ) +
                   ( this.colors.tr[ 1 ][ 0 ] * ( ( x ) * ( 1 - y )) ) +
                   ( this.colors.bl[ 1 ][ 0 ] * ( ( 1 - x ) * ( y )) ) +
                   ( this.colors.br[ 1 ][ 0 ] * ( ( x ) * ( y )) )

    p2Color[ 1 ] = ( this.colors.tl[ 1 ][ 1 ] * ( ( 1 - x ) * ( 1 - y ) ) ) +
                   ( this.colors.tr[ 1 ][ 1 ] * ( ( x ) * ( 1 - y ) ) ) +
                   ( this.colors.bl[ 1 ][ 1 ] * ( ( 1 - x ) * ( y ) ) ) +
                   ( this.colors.br[ 1 ][ 1 ] * ( ( x ) * ( y ) ) )

    p2Color[ 2 ] = ( this.colors.tl[ 1 ][ 2 ] * ( ( 1 - x ) * ( 1 - y ) ) ) +
                   ( this.colors.tr[ 1 ][ 2 ] * ( ( x ) * ( 1 - y ) ) ) +
                   ( this.colors.bl[ 1 ][ 2 ] * ( ( 1 - x ) * ( y ) ) ) +
                   ( this.colors.br[ 1 ][ 2 ] * ( ( x ) * ( y ) ) )

    let p1ColorString = 'hsl(' + p1Color[ 0 ] + ', ' + p1Color[ 1 ] + '%, ' + p1Color[ 2 ] + '% ' + ')'
    // console.log( p1ColorString )
    this.pane1.style.backgroundColor = p1ColorString

    let p2ColorString = 'hsl(' + p2Color[ 0 ] + ', ' + p2Color[ 1 ] + '%, ' + p2Color[ 2 ] + '% ' + ')'
    // console.log( p2ColorString )
    this.pane2.style.backgroundColor = p2ColorString


  }
  

  update = ( time ) => {

    this.time = time

    // let timeoutPeriod = 6000
    let timeoutPeriod = 4000
    if( player.justPlayed ) {
      this.lastUpdated = this.time + timeoutPeriod
      player.justPlayed = false
    }
    let timeoutTotal = this.time - this.lastUpdated 
    if( timeoutTotal > timeoutPeriod ) {

      // let fadeTime = 4000
      let fadeTime = 3000
      let fadeUp = Math.min( timeoutTotal - timeoutPeriod, fadeTime ) * 1 / fadeTime
      let timeFactor = .0002
      // let noiseFactor = fadeUp * .25
      let noiseFactor = fadeUp * .33
      // let timeFactor = .001
      let noiseTime = this.time * timeFactor
      
      let node1MoveX = this.simplex.noise3D( this.node1.x, this.node1.y, noiseTime + 235 ) * noiseFactor
      let node1MoveY = this.simplex.noise3D( this.node1.y, this.node1.x, noiseTime + 743 ) * noiseFactor

      // console.log( 'noiseFactor:', noiseFactor )
      // console.log( 'fadeUp:', fadeUp )
      // console.log( 'noise move x, y: ', node1MoveX, ',', node1MoveY )

      let moveX = lerp( this.node1.last[ 0 ], .5, fadeUp ) + node1MoveX * fadeUp
      let moveY = lerp( this.node1.last[ 1 ], .5, fadeUp ) + node1MoveY * fadeUp

      // console.log( 'move x, y: ', moveX, ',', moveY )

      this.updateNode( 'node1', moveX, moveY )

    } 

    
  }

  getTransform = ( el ) => {
    var values = el.style.transform.split(/\w+\(|\);?/)
    if (!values[1] || !values[1].length) {
        return []
    }
    return values[1].split(/,\s?/g)
  }

  newTrack = ( newColors ) => {
    
    this.colors = newColors

    this.player.updateNode( 'node1', node1.x, node1.y )
    this.updateNode( 'node1', node1.x, node1.y, true )
    

  }

  resize = () => {

    this.update( 'node1', this.node1.x, this.node1.y )

  }

}

export default XYPad
