import '../styles/index.scss';

import { Draggable } from '@shopify/draggable';

import XYPad from './XYPad'
import Loader from './Loader'
import Audio from './Audio'
import Player from './Player.js';
import Controls from './Controls.js'

// Load JSON text from server hosted file and return JSON parsed object
function loadJSON(filePath) {
  // Load json file;
  var json = loadTextFileAjaxSync(filePath, "application/json");
  // Parse json
  return JSON.parse(json);
}   

// Load text with Ajax synchronously: takes path to file and optional MIME type
function loadTextFileAjaxSync(filePath, mimeType)
{
  var xmlhttp=new XMLHttpRequest();
  xmlhttp.open("GET",filePath,false);
  if (mimeType != null) {
    if (xmlhttp.overrideMimeType) {
      xmlhttp.overrideMimeType(mimeType);
    }
  }
  xmlhttp.send();
  if ( xmlhttp.status == 200 )
  {
    return xmlhttp.responseText;
  }
  else {
    // TODO Throw exception
    return null;
  }
}

if( process.env.NODE_ENV == 'development' ) {
	const config = window.config = loadJSON( '../../public/assets/config/config.json' )
} else {
	const config = window.config = loadJSON( 'public/assets/config/config.json' )	
}


const loader = window.loader = new Loader( config )
let preloadArray = config.tracks.slice( 0 )
preloadArray.splice( loader.trackNumber - 1, 1 )
const preloadTracks = window.preloadTracks = preloadArray
const audio = window.audio = new Audio( loader.track.audio )
const tracks = window.tracks = {}
tracks[ loader.trackNumber ] = {}
tracks[ loader.trackNumber ].audio = audio
tracks[ loader.trackNumber ].images = loader.track.images
tracks[ loader.trackNumber ].loaded = true
const player = window.player = new Player( audio, loader )
const controls = window.controls = new Controls()
const xyPad = window.xyPad = new XYPad( player, document.getElementById( 'xyPad' ), loader.track.images, loader.colors )


// attach utilties to loader
loader.player = player
loader.xyPad = xyPad
loader.tracks = tracks
window.draggable = false

const loading = document.getElementById( 'loading' )
const progressBar = document.getElementById( 'progress' )
const playPause = document.getElementById( 'playPause' )
const previous = document.getElementById( 'previous' )
const next = document.getElementById( 'next' )
const info = document.getElementById( 'info' )
const infoButton = document.getElementById( 'info-button' )
const infoContainer = document.getElementById('info-container')
const infoWindow = document.getElementById('info-window')
const trackId = document.getElementById( 'track-id' )

trackId.innerText = loader.track.title
info.outerHTML = loader.about
loader.loading = loading

infoWindow.onclick = infoButton.onclick = ( e ) => {

	console.log( e )

	if( e.target.id != 'info-container' && e.target.parentNode.id != 'info-container' ) {
		if( infoWindow.style.display === 'flex' ) {
			infoWindow.style.display = 'none'
			infoWindow.style.opacity = 0
		} else {
			infoWindow.style.display = 'flex'
			infoWindow.style.opacity = 1
		}	
	}
	
}

// 
// when first audio has loaded
// 
audio.setBuffersLoaded( function(){
	//all buffers are loaded.	
	console.log( 'first buffers loaded' )

	// attach audio and all the functions
	player.setCurrentAudio( audio )
	player.attachElements( playPause, previous, next, trackId )
	
	player.onclick = playPause.onclick = player.togglePausePlay

	window.draggable = new Draggable( xyPad.element, {
		draggable : ".node"
	} )

	draggable.on('drag:start', ( e ) => {
		let sourceMatrix = xyPad.getTransform( e.data.originalSource )
		let sourceX = sourceMatrix[ 0 ] || 0
		let sourceY = sourceMatrix[ 1 ] || 0
		draggable.sourceXY = { x : parseInt( sourceX ), y : parseInt( sourceY ) }
	})
	console.log( 'drag:start' )

	draggable.on('drag:move', ( e ) => {
		let og = e.data.originalSource
		og.style.display = 'block'
		let thisNode = og.id
		let mirror = document.getElementsByClassName( 'draggable-mirror' )[ 0 ]
		let mirrorMatrix = xyPad.getTransform( mirror );
		let mirrorX = parseInt( mirrorMatrix[ 0 ] )
		let mirrorY = parseInt( mirrorMatrix[ 1 ] )
		let newX = draggable.sourceXY.x + mirrorX
		let newY = draggable.sourceXY.y + mirrorY

		// prevent over drag
		if( newX < 0 ) newX = 0
		if( newX + window.innerWidth * .5 > window.innerWidth ) newX = window.innerWidth - window.innerWidth * .5
		if( newY < 0 ) newY = 0
		if( newY + window.innerHeight * .5 > window.innerHeight ) newY = window.innerHeight - window.innerHeight * .5

		og.style.transform = "translate3d( " + newX + "px, " + newY + "px, " + "0px )"

		newX = (( newX + window.innerWidth * .5 * .5 ) / ( window.innerWidth ) )
		newY = (( newY + window.innerHeight * .5 * .5 ) / ( window.innerHeight ) )

		xyPad.updateNode( thisNode, newX, newY, true )

	})

	console.log( 'drag:move' )

	xyPad.reset()
	console.log( 'reset' )
	// xyPad.update( Date.now() )

	trackId.classList.add( 'hide' )

	// hide loading
	console.log( 'hide loader' )
	loading.style.display = 'none'
	trackId.classList.add('hide')

	preoloadRemaining()

	
})

const preoloadRemaining = () => {

	const thisPreload = preloadArray.splice( 0, 1 )[ 0 ]
	console.log( thisPreload )
	let newAudio = new Audio( thisPreload.audio )
	tracks[ thisPreload.trackNumber ] = {}
	tracks[ thisPreload.trackNumber ].audio = newAudio
	tracks[ thisPreload.trackNumber ].images = thisPreload.images
	newAudio.setBuffersLoaded( () => {
		console.log( 'preloaded another track', thisPreload.trackNumber )
		tracks[ thisPreload.trackNumber ].loaded = true
		loader.onLoad( thisPreload.trackNumber )
	})

	if( preloadArray.length ) { 
		preoloadRemaining()
	} 

}

// animation
var start = null

function animate( timestamp ) {

	if (!start) {
		start = timestamp
	}

	if( !player.paused && !progressBar.isUpdating ) {
		var currentProgress = player.progress()
		var lastProgress = progress.value
		progress.value = currentProgress
		// on end
		if( currentProgress == 0 && lastProgress >= 1 ) {
			console.log( 'ended' )
			player.pause()
			loader.next()
		}

		xyPad.update( timestamp )

	}

	progressBar.isUpdating = false

	window.requestAnimationFrame(animate)

}

window.requestAnimationFrame(animate)


controls.onRangeChange( progressBar, function(ev){
	progressBar.isUpdating = true
	progressBar.value = ev.target.value
	player.currentTime = progressBar.value * player.duration
	player.seek()
} )