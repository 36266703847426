import Tone from 'Tone'

class Audio {

	constructor( audioPaths ) {
		this.loadAudio( audioPaths )
		this.loadCount = 0
	}

	loadAudio = ( audioPaths ) => {

		this.loadTotal = audioPaths.length

		this.audio1 = new Tone.Player({
			url  : audioPaths[ 0 ],
			loop : false,
			onload: this.allLoad
		})

		this.audio2 = new Tone.Player({
			url  : audioPaths[ 1 ],
			loop : false,
			onload: this.allLoad	
		})

		this.audio3 = new Tone.Player({
			url  : audioPaths[ 2 ],
			loop : false,
			onload: this.allLoad	
		})

		this.audio4 = new Tone.Player({
			url  : audioPaths[ 3 ],
			loop : false,
			onload: this.allLoad	
		})




			
		for( var i = 1; i <= this.loadTotal; i++ ) {
			this[ 'audio' + i ].volume.value = 0
			this[ 'audio' + i ].toMaster() 
		}
		

	}

	start = ( offset ) => {

		this.audio1.start( )
		this.audio2.start( )
		this.audio3.start( )
		this.audio4.start( )
		if( offset ) {
			this.audio1.seek( offset )
			this.audio2.seek( offset )	
			this.audio3.seek( offset )
			this.audio4.seek( offset )	
		}
	}

	stop = () => {

		this.audio1.stop()
		this.audio2.stop()
		this.audio3.stop()
		this.audio4.stop()

	}

	allLoad = () => {
		console.log( 'loaded' )
		this.loadCount ++
		if( this.loadCount == this.loadTotal ) {
			this.buffersLoaded()
		}
	}

	setBuffersLoaded = ( cb ) => {
		this.buffersLoaded = cb
	}

	seek = ( newTime ) => {
		this.audio1.seek( newTime )
		this.audio2.seek( newTime )	
		this.audio3.seek( newTime )	
		this.audio4.seek( newTime )	
	}
	

}

export default Audio